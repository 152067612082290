<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>

  @supports (bottom: env(safe-area-inset-bottom)){
    #app {
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  #app {
    font-family: PingFangSC-Medium, PingFang SC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
  }

  * {
    margin: 0;
    padding: 0;
  }

  html, body {
    width: 100vw;
    height: 100vh;
  }
</style>
