import request from '../utils/request'

export default {

    //告诉TV端已经扫码
    scanQRCode: (params) => request.get(`/web/comein/?state=${params}`),
    //微信授权后回调获取用户信息
    getUserInfo: (params) => request.get(`/web/auth?code=${params.code}&state=${params.state}`, params),
    //获取state(后续删除)
    getState: (params) => request.post(`/api/wx/url`, params),
    //创建订单
    createOrder: (params) => request.post(`/web/createOrder`, params),
    //查询订单信息
    queryOrder: (params) => request.get(`/web/queryOrder?openid=${params}`),
}