import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'lib-flexible/flexible.js'
import 'vant/lib/index.css'
import { Checkbox, Dialog, Toast  } from 'vant'
import axios from 'axios'
import api from '@/api/index'
import md5 from 'js-md5'

Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Toast)
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
