import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);




const routes = [
    {
        path: "/",
        name: "main",
        component: solve => require(['@/views/index.vue'], solve)
    },
    {
        path: "/payment",
        name: "payment",
        component: solve => require(['@/views/payment.vue'], solve)
    },
    {
        path: "/detail",
        name: "detail",
        component: solve => require(['@/views/detail.vue'], solve)
    },
    {
        path: "/success",
        name: "success",
        component: solve => require(['@/views/success.vue'], solve)
    },
    {
        path: "/failed",
        name: "failed",
        component: () => import('@/views/failed.vue'),
        component: solve => require(['@/views/failed.vue'], solve)
    }
];


const router = new VueRouter({
    mode: "history",
    routes
});

export default router;