import axios from "axios";
import { Toast } from "vant";

export const baseUrl = getBaseUrl(); //获取当前域名
// export const baseUrl = "http://wxtest.ikukan.cn/";//获取当前域名

// 获取当前网页的协议+域名
function getBaseUrl() {
    let baseURL = ''
    if (!window.location.origin) { // 兼容IE，IE11版本下location.origin为undefined
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    } else {
        baseURL = window.location.origin
    }
    return baseURL
}
// export const baseUrl = "http://121.40.221.119:8813/";//测试环境
// export const baseUrl = "http://appmanage.ikukan.cn/";//正式环境
// export const baseUrl = process.env.VUE_APP_BASE_URL || "http://192.168.0.22:8083/";//本地环境
var service = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json; charset=UTF-8"
    },
});


// 请求拦截器
service.interceptors.request.use(
    config => {
        // // 在发送请求之前做些什么
        // let token = getToken();
        // if (token) {
        //     config.headers.Authorization = token;
        // }
        return config
    },
    error => {
        // 处理请求错误 
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const {code, msg} = response.data;
        if (code !== 200) {
            onError(msg);
            return false;
        }

        return response.data || {};
    },
    error => {
        const message =
            (error.response && error.response.data.message) || error.message;

        onError(message);
        return Promise.reject(error);
    }
)

function onError(message) {
    Toast.fail({
        duration: 2000,
        message: message
    });
}

export default service